import React from 'react'
import PropTypes from 'prop-types'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

import Layout from '../components/layout'
import Explore from '../components/explore'

import AvatarGroup from '../assets/images/diverse-global-team.png'

import CoffeeIcon from '../assets/images/icons/about/coffee.svg'
import DiscoveryIcon from '../assets/images/icons/about/discovery.svg'
import InnovationIcon from '../assets/images/icons/about/innovation.svg'
import AllNightersIcon from '../assets/images/icons/about/nighters.svg'
import PrototypeIcon from '../assets/images/icons/about/prototype.svg'
import VideoConferenceIcon from '../assets/images/icons/about/video-conference.svg'

const Card = ({ icon, description }) => (
  <div className="flex items-center rounded bg-light-gray mb-8 lg:mx-4 py-8 px-6">
    <div className="rounded-[50%] bg-white h-[55px] min-w-[55px] w-[55px] flex items-center justify-center" >
      <img src={icon} />
    </div>
    <div className="font-GTWalsheimPro text-xl ml-5 lg:whitespace-nowrap tracking-[-0.02em]">
      {description}
    </div>
  </div>
)

Card.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string
}

function AboutPage () {
  return (
    <Layout>
      <div className="text-white bg-main-black lg:pt-[120px]">
        <div className="default-container mx-auto lg:pb-12">
          <div className="lg:max-w-[1002px] flex-col justify-center text-center mx-auto">
            <div className="font-inter uppercase text-xl text-dark-gray font-semibold">Our Mission</div>
            <h1 className="text-2xl mt-9 lg:mt-6 lg:text-6xl lg:leading-tight font-GTWalsheimPro font-medium">
              To transform and modernise <br /> the business of law
            </h1>
            <div className="text-sm leading-7 lg:text-xl lg:leading-8 tracking-[-0.02em] font-GTWalsheimPro mt-6">
              Law is complex. Doing business in law doesn’t need to be. We are working towards providing the legal industry with the modern day tools that can fundamentally transform how legal services are delivered. We want to empower both law firms and legal departments to easily be able to  collaboratively design, manage, and execute legal matters with greater accuracy and efficiency, with improved transparency and accountability, while maintaining positive working relations.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[linear-gradient(to_bottom,_#110D1B_50%,_#FFFFFF_50%)]">
        <div className="relative lg:max-w-[800px] mx-auto w-full flex items-center justify-center">
          <video className="w-full lg:w-[800px] lg:rounded-lg" autoPlay loop muted playsInline>
            <source src={'/videos/hand-shake-animation-progress.mp4'} type="video/mp4" />
          </video>
          <svg className="hidden lg:block absolute bottom-0 left-[38px] translate-y-[50%]" width="73" height="73" viewBox="0 0 73 73" fill="none">
            <circle cx="36.5" cy="36.5" r="36.5" fill="#FF4B6C"/>
          </svg>
          <svg className="hidden lg:block absolute top-[70px] left-[-85px]" width="29" height="29" viewBox="0 0 29 29" fill="none">
            <circle cx="14.5" cy="14.5" r="14.5" fill="white"/>
          </svg>
          <svg className="hidden lg:block absolute top-0 right-[-73px] translate-x-[100%]" width="68" height="68" viewBox="0 0 68 68" fill="none">
            <circle cx="34" cy="34" r="34" fill="#FDBE2C"/>
          </svg>
        </div>
      </div>
      <div className="default-container">
        <div className="text-center flex-col font-GTWalsheimPro lg:max-w-[1002px] mx-auto">
          <h2 className="text-2xl lg:text-4xl font-medium mb-6">
            Creating technology to make us superhuman
          </h2>
          <h3 className="text-xl lg:text-3xl leading-9 mb-6 tracking-[-0.02em]">
            Every great product has a great story
          </h3>
          <div className="text-sm leading-7 lg:text-xl lg:leading-8 tracking-[-0.02em]">
            <p>
              While we pride ourselves on beautiful technology that ‘just
              works’, behind the scenes, we’ve endured an endless marathon in
              coming to understand the problems we were tasked with solving:
            </p>
            <br />
            <p>
              Endless days. Zoom fatigue. Webex issues. 10+ law firm
              development partners. Over 1,000+ hours in discovery and design
              sessions. Complex regulatory environments. Compliance and
              security requirements of a major financial institution. Working
              with industry bodies. Obtaining partner and CEO buy-in. 500+
              product and training demonstrations.
            </p>
            <br />
            <p>
              The buy-in we’ve obtained from industry has been phenomenal. The
              generous hours offered by our steering group partners in sharing
              their insight, battle-stories and visions of utopia, has allowed
              us to re-create and enhance the experience of the delivery and
              consumption of legal services between in-house teams and their
              law firms.
            </p>
          </div>
        </div>
        <div className="hidden lg:block mt-20">
          <ParallaxProvider>
            <Parallax
              translateX={[-20, 0]}
            >
              <div className="flex">
                <Card
                  icon={CoffeeIcon}
                  description="Lots of caffeine"
                />
                <Card
                  icon={PrototypeIcon}
                  description="Prototyping and Continuous Feedback"
                />
                <Card
                  icon={DiscoveryIcon}
                  description="Discovery and Design Sessions"
                />
              </div>
            </Parallax>
            <Parallax
              translateX={[20, 0]}
            >
              <div className="flex">
                <Card
                  icon={AllNightersIcon}
                  description="All nighters"
                />
                <Card
                  icon={InnovationIcon}
                  description="Innovation Consortiums"
                />
                <Card
                  icon={VideoConferenceIcon}
                  description="“Hello, can you hear me” video conferences"
                />
              </div>
            </Parallax>
          </ParallaxProvider>
        </div>
        <div className="lg:hidden flex-col justify-center mx-auto max-w-max mt-12">
          <Card
            icon={DiscoveryIcon}
            description="Discovery and Design Sessions"
          />
          <Card
            icon={CoffeeIcon}
            description="Lots of caffeine"
          />
          <Card
            icon={PrototypeIcon}
            description="Prototyping and Continuous Feedback"
          />
          <Card
            icon={AllNightersIcon}
            description="All nighters"
          />
          <Card
            icon={InnovationIcon}
            description="Innovation Consortiums"
          />
          <Card
            icon={VideoConferenceIcon}
            description="“Hello, can you hear me” video conferences"
          />
        </div>
      </div>
      <div className="bg-light-gray">
        <div className="default-container bg-light-gray">
          <div className="m-auto max-w-[800px] text-center font-GTWalsheimPro">
            <h3 className="font-medium text-2xl lg:text-4xl">
              A diverse and global team of doers
            </h3>
            <div className="leading-7 text-sm lg:text-2xl mt-8">
              LegalEye, a LawAdvisor Ventures product, is built by a dedicated, diverse workforce spanning multiple countries, cultures.
            </div>
            <img
              src={AvatarGroup}
              className="h-auto lg:h-[120px] mt-12 lg:mt-16 mx-auto"
            />
            <a
              href="https://lawadvisor.ventures/careers"
              className="mt-6 lg:my-16 inline-block text-sm lg:text-lg rounded-full px-6 py-2 border border-black"
              target="_blank"
              rel="noreferrer"
            >
              Interested in joining the team?{' '}
              <span className="text-leg-red">
                View Careers
              </span>
            </a>
          </div>
        </div>
      </div>
      <Explore />
    </Layout>
  )
}

export default AboutPage
